import React, { useCallback } from "react";
import Header from "components/templates/Header";
import styled from "styled-components";
import { Button } from "antd";
import AuthStore from "services/auth.service";

interface MainHeaderProps {}

const S = {
  LogoutWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    width: 100%;
  `
};

const MainHeader: React.FC<MainHeaderProps> = (props) => {
  const { deleteAuthToken } = AuthStore.useAuth();

  const logout = useCallback(() => {
    deleteAuthToken();
  }, [deleteAuthToken]);

  return (
    <Header>
      <S.LogoutWrapper>
        <Button onClick={logout}>verlassen</Button>
      </S.LogoutWrapper>
    </Header>
  );
};

export default MainHeader;
