import React from "react";
import { Input, Form, Icon } from "antd";
import { useLoginFormContext } from "services/login.service";

export const PasswordInput: React.FC = () => {
  const { getFieldDecorator } = useLoginFormContext();

  const formInput = getFieldDecorator("password", {
    rules: [{ required: true, message: "Bitte trage dein Passwort ein." }]
  })(<Input type="password" prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} />);
  return <Form.Item label="Passwort">{formInput}</Form.Item>;
};
