import { Resolvers } from "apollo-client/core/types";
import { GET_IS_SIDEMENU_COLLAPSED_QUERY } from "./graphql/queries";

export const resolvers: Resolvers | Resolvers[] = {
  Mutation: {
    //@ts-ignore
    toggleSidemenu: (_: any, args, { cache }) => {
      const oldState = cache.readQuery({ query: GET_IS_SIDEMENU_COLLAPSED_QUERY });

      cache.writeData({ data: { isSidemenuCollapsed: oldState && !oldState.isSidemenuCollapsed } });
      return oldState && !oldState.isSidemenuCollapsed;
    }
  }
};
