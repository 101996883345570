import React, { useRef, useEffect, useCallback, useState } from "react";
import styled from "styled-components";

import animationData from "assets/lottie/5756-like-5x.json";
import Lottie from "react-lottie";
import useWindowSize from "hooks/use-window-size";

interface MainPageProps {}
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {}
};

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    margin-top: 50px;
    overflow: hidden;
    width: 100%;
  `
};
const MainPage: React.FC<MainPageProps> = (props) => {
  const audioref = useRef<HTMLAudioElement>(null);
  const isInit = useRef<Boolean>(false);
  // eslint-disable-next-line
  const [playing, setIsPlaying] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (audioref.current) {
      audioref.current.load();
    }
  }, []);

  const handleToggle = useCallback(() => {
    setIsPlaying((oldState) => {
      if (!!oldState) {
        if (audioref.current) audioref.current.pause();
      } else {
        if (audioref.current) audioref.current.play();
      }
      return !oldState;
    });
  }, [setIsPlaying]);

  return (
    <S.Wrapper
      onClick={handleToggle}
      onTouchStart={() => {
        if (audioref.current && !isInit.current) {
          audioref.current.play();
          setIsPlaying(true);
          isInit.current = true;
        }
      }}
    >
      <Lottie
        options={defaultOptions}
        height={size && size.width && size.width - 10}
        width={size && size.width && size.width - 10}
        isClickToPauseDisabled
      />
      <audio ref={audioref} autoPlay src={require("assets/audio/gitare.mp3")} loop preload="auto"></audio>
    </S.Wrapper>
  );
};

export default MainPage;
