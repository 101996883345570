import qgl from "graphql-tag";

const typeDefs = qgl`
schema {
    query: Query
    mutation: Mutation
  }
  
  type Query {
    isSidemenuCollapsed: Boolean!
  }
  
  type Mutation {
    toggleSidemenu: Boolean!
  }
`;

export default typeDefs;
