import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "components/ErrorBoundary";
import AuthStore from "services/auth.service";
import { getApollo } from "utils/apollo";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import "./index.css";

interface IApolloState {
  isLoadingCompleted: boolean;
  client: ApolloClient<NormalizedCacheObject> | null;
}
const Main: React.FC = () => {
  const [state, setState] = useState<IApolloState>({ isLoadingCompleted: false, client: null });

  useEffect(() => {
    const init = async () => {
      const apollo = await getApollo();
      setState({ client: apollo.client, isLoadingCompleted: true });
    };
    init();
  }, [setState]);

  if (!state.client) return null;
  return (
    <ErrorBoundary>
      <ApolloHooksProvider client={state.client}>
        <AuthStore.Provider>
          <App />
        </AuthStore.Provider>
      </ApolloHooksProvider>
    </ErrorBoundary>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
