import React from "react";
import MainLayout from "components/templates/layouts/MainLayout";
import { RouteComponentProps } from "@reach/router";
import MainPage from "./main/MainPage";

interface AuthorizedProps {}

const Authorized: React.FC<AuthorizedProps & RouteComponentProps> = () => {
  return (
    <MainLayout>
      <MainPage></MainPage>
    </MainLayout>
  );
};

export default Authorized;
