import React from "react";
import { Router, Redirect } from "@reach/router";
import Authorized from "pages/Authorized";
import "./App.css";
import NotFound from "pages/404";
import Login from "pages/login/Login";
import PrivateRoute from "components/PrivateRoute";
import AuthStore from "services/auth.service";

const RouterComponent: React.FC = ({ children }) => {
  // Shorthand of <React.Fragment>{children}</React.Fragment>
  return <>{children}</>;
};

const App: React.FC = () => {
  const { token } = AuthStore.useAuth();
  return (
    <Router component={RouterComponent}>
      {token ? <Redirect from="login" to="/" noThrow /> : null}
      <Login path="login" />
      <PrivateRoute as={Authorized} path="/*" />
      <NotFound default />
    </Router>
  );
};

export default App;
