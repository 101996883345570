import React from "react";

interface IProps {}
interface IState {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  // componentDidCatch(error, info) {
  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    console.log("error", error);
    console.log("info", info);
    // You can also log the error to an error reporting service like Sentry/LogRocket
    // logErrorToMyService(error, info);
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h3>Oops, something went wrong!</h3>
          <p>
            Try to refresh this page or load <a href="/">Home</a>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
