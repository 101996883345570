import React, { useContext } from "react";
import { createContext, ReactNode } from "react";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { FormComponentProps } from "antd/lib/form";
import { Form } from "antd";

export const FormContext = createContext<WrappedFormUtils<any> | null>(null);

type FormContextConsumerProps = {
  children: (value: WrappedFormUtils) => ReactNode;
};

export const FormContextConsumer = (props: FormContextConsumerProps): JSX.Element => {
  const { children } = props;
  return (
    <FormContext.Consumer>
      {(form) => {
        if (!form) {
          throw new Error("Missing FormContextProvider in its parent.");
        }
        return children(form);
      }}
    </FormContext.Consumer>
  );
};

type FormContextProviderProps = { children?: ReactNode } & FormComponentProps;
function FormContextProviderImpl(props: FormContextProviderProps): JSX.Element {
  const { form, children } = props;
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
}
export const FormContextProvider = Form.create()(FormContextProviderImpl);

export const useLoginFormContext = () => {
  const form = useContext(FormContext);
  if (!form) {
    throw new Error("Missing FormContextProvider in its parent.");
  }
  return form;
};
