import React from "react";
import BlankLayout from "components/templates/layouts/BlankLayout";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { Row, Col, Typography } from "antd";
import LoginForm from "./LoginForm";
import { FormContextProvider } from "services/login.service";
import animationData from "assets/lottie/12063-christmas-tree.json";
import Lottie from "react-lottie";

interface LoginProps {}

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100vh;
  `,
  Container: styled.div`
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    border-width: 0.5px;
    border-style: solid;
    padding: 25px;
    border-radius: 10px;
    margin-top: 8%;
  `,
  Title: styled(Typography.Title)`
    text-align: center;
  `
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Login: React.FC<LoginProps & RouteComponentProps> = () => {
  return (
    <BlankLayout>
      <S.Wrapper>
        <Row type="flex" justify="center" align="middle">
          <Col xs={20} sm={20} md={8} lg={6} xl={6}>
            <S.Container>
              <Lottie options={defaultOptions} height={200} width={200} isClickToPauseDisabled />
              <FormContextProvider>
                <LoginForm />
              </FormContextProvider>
            </S.Container>
          </Col>
        </Row>
      </S.Wrapper>
    </BlankLayout>
  );
};

export default Login;
