import React, { useCallback } from "react";
import { Input, Form, Icon } from "antd";
import { useLoginFormContext } from "services/login.service";

// eslint-disable-next-line
const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EmailInput: React.FC = () => {
  const { getFieldDecorator } = useLoginFormContext();

  const checkEmail = useCallback((rule, value, cb) => {
    if (reg.test(value)) {
      cb();
    } else {
      cb("not a valid german ZIP");
    }
  }, []);

  const formInput = getFieldDecorator("email", {
    rules: [{ required: true, message: "Bitte trage deine E-Mail ein.", validator: checkEmail }]
  })(<Input type="email" prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} />);
  return <Form.Item label="E-Mail">{formInput}</Form.Item>;
};
