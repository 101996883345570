import React, { useCallback, useState } from "react";
import { useLoginFormContext } from "services/login.service";
import { Form, Row, Button } from "antd";
import AuthStore from "services/auth.service";
import { EmailInput } from "./EmailInput";
import { PasswordInput } from "./PasswordInput";

interface LoginFormProps {}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { validateFields } = useLoginFormContext();
  const [loading, setLoading] = useState(false);
  const { setAuthToken } = AuthStore.useAuth();

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      validateFields(async (err, fieldsValue) => {
        if (!err) {
          setLoading(true);
          window.blur();

          if (fieldsValue && fieldsValue.email === "julia.wallner@gmx.de" && fieldsValue.password === "atemlos") {
            setTimeout(() => {
              window.scrollTo(0, 0);

              setAuthToken("token");

              setLoading(false);
            }, 1000);
          } else {
            setLoading(false);
          }
        }
      });
    },
    [setLoading, validateFields, setAuthToken]
  );
  return (
    <Form layout="vertical" onSubmit={onSubmit} hideRequiredMark>
      <Row>
        <EmailInput></EmailInput>
      </Row>
      <Row>
        <PasswordInput />
      </Row>
      <Row type="flex" justify="center" style={{ marginTop: 30 }}>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="default" loading={loading}>
            Anmelden
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default LoginForm;
