import React from "react";
import styled from "styled-components";

interface HeaderProps {}

const S = {
  Header: styled.div`
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
  `
};

const Header: React.FC<HeaderProps> = (props) => {
  return <S.Header>{props.children}</S.Header>;
};

export default Header;
