/* eslint-disable */
import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = Node & {
  __typename?: "Account";
  id: Scalars["ID"];
};

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE"
}

export type Mutation = {
  __typename?: "Mutation";
  toggleSidemenu: Scalars["Boolean"];
  loginWithEmailAndPassword: TokenPayload;
};

export type MutationLoginWithEmailAndPasswordArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type Node = {
  id: Scalars["ID"];
};

export type Query = {
  __typename?: "Query";
  isSidemenuCollapsed: Scalars["Boolean"];
  account: Account;
};

export type TokenPayload = {
  __typename?: "TokenPayload";
  token: Scalars["String"];
};

export type FUserFragment = { __typename?: "Account" } & Pick<Account, "id">;

export type LoginWithEmailAndPasswordMutationVariables = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LoginWithEmailAndPasswordMutation = { __typename?: "Mutation" } & {
  loginWithEmailAndPassword: { __typename?: "TokenPayload" } & Pick<TokenPayload, "token">;
};

export type AccountQueryVariables = {};

export type AccountQuery = { __typename?: "Query" } & { account: { __typename?: "Account" } & FUserFragment };

export type ToggleSidemenuMutationVariables = {};

export type ToggleSidemenuMutation = { __typename?: "Mutation" } & Pick<Mutation, "toggleSidemenu">;

export type GetIsSidemenuCollapsedQueryVariables = {};

export type GetIsSidemenuCollapsedQuery = { __typename?: "Query" } & Pick<Query, "isSidemenuCollapsed">;

export const FUserFragmentDoc = gql`
  fragment FUser on Account {
    id
  }
`;
export const LoginWithEmailAndPasswordDocument = gql`
  mutation LoginWithEmailAndPassword($email: String!, $password: String!) {
    loginWithEmailAndPassword(email: $email, password: $password) {
      token
    }
  }
`;
export type LoginWithEmailAndPasswordMutationFn = ApolloReactCommon.MutationFunction<
  LoginWithEmailAndPasswordMutation,
  LoginWithEmailAndPasswordMutationVariables
>;

/**
 * __useLoginWithEmailAndPasswordMutation__
 *
 * To run a mutation, you first call `useLoginWithEmailAndPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithEmailAndPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithEmailAndPasswordMutation, { data, loading, error }] = useLoginWithEmailAndPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginWithEmailAndPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginWithEmailAndPasswordMutation,
    LoginWithEmailAndPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LoginWithEmailAndPasswordMutation, LoginWithEmailAndPasswordMutationVariables>(
    LoginWithEmailAndPasswordDocument,
    baseOptions
  );
}
export type LoginWithEmailAndPasswordMutationHookResult = ReturnType<typeof useLoginWithEmailAndPasswordMutation>;
export type LoginWithEmailAndPasswordMutationResult = ApolloReactCommon.MutationResult<
  LoginWithEmailAndPasswordMutation
>;
export type LoginWithEmailAndPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginWithEmailAndPasswordMutation,
  LoginWithEmailAndPasswordMutationVariables
>;
export const AccountDocument = gql`
  query Account {
    account {
      ...FUser
    }
  }
  ${FUserFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
  return ApolloReactHooks.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
}
export function useAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = ApolloReactCommon.QueryResult<AccountQuery, AccountQueryVariables>;
export const ToggleSidemenuDocument = gql`
  mutation ToggleSidemenu {
    toggleSidemenu @client
  }
`;
export type ToggleSidemenuMutationFn = ApolloReactCommon.MutationFunction<
  ToggleSidemenuMutation,
  ToggleSidemenuMutationVariables
>;

/**
 * __useToggleSidemenuMutation__
 *
 * To run a mutation, you first call `useToggleSidemenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSidemenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSidemenuMutation, { data, loading, error }] = useToggleSidemenuMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleSidemenuMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleSidemenuMutation, ToggleSidemenuMutationVariables>
) {
  return ApolloReactHooks.useMutation<ToggleSidemenuMutation, ToggleSidemenuMutationVariables>(
    ToggleSidemenuDocument,
    baseOptions
  );
}
export type ToggleSidemenuMutationHookResult = ReturnType<typeof useToggleSidemenuMutation>;
export type ToggleSidemenuMutationResult = ApolloReactCommon.MutationResult<ToggleSidemenuMutation>;
export type ToggleSidemenuMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleSidemenuMutation,
  ToggleSidemenuMutationVariables
>;
export const GetIsSidemenuCollapsedDocument = gql`
  query GetIsSidemenuCollapsed {
    isSidemenuCollapsed @client
  }
`;

/**
 * __useGetIsSidemenuCollapsedQuery__
 *
 * To run a query within a React component, call `useGetIsSidemenuCollapsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsSidemenuCollapsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsSidemenuCollapsedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsSidemenuCollapsedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetIsSidemenuCollapsedQuery, GetIsSidemenuCollapsedQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetIsSidemenuCollapsedQuery, GetIsSidemenuCollapsedQueryVariables>(
    GetIsSidemenuCollapsedDocument,
    baseOptions
  );
}
export function useGetIsSidemenuCollapsedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIsSidemenuCollapsedQuery, GetIsSidemenuCollapsedQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetIsSidemenuCollapsedQuery, GetIsSidemenuCollapsedQueryVariables>(
    GetIsSidemenuCollapsedDocument,
    baseOptions
  );
}
export type GetIsSidemenuCollapsedQueryHookResult = ReturnType<typeof useGetIsSidemenuCollapsedQuery>;
export type GetIsSidemenuCollapsedLazyQueryHookResult = ReturnType<typeof useGetIsSidemenuCollapsedLazyQuery>;
export type GetIsSidemenuCollapsedQueryResult = ApolloReactCommon.QueryResult<
  GetIsSidemenuCollapsedQuery,
  GetIsSidemenuCollapsedQueryVariables
>;
