import React from "react";
import { RouteComponentProps } from "@reach/router";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps & RouteComponentProps> = () => {
  return <div>Not found. 404.</div>;
};

export default NotFound;
