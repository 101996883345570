import React, { createContext, useContext, useState, useCallback, useMemo } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { getApollo } from "utils/apollo";
import { useLoginWithEmailAndPasswordMutation } from "graphql/types";

/**
 * Maybe store token in cookie
 */
export const APP_TOKEN_NAME = "my-boilerplate-token";

interface IAuthContext {
  token: string | null;
  setAuthToken: (token: string | null) => void;
  deleteAuthToken: () => void;
}
interface IAuthProvider {
  initialValue?: string | null;
  children: React.ReactNode;
}
const AuthContext = createContext<IAuthContext | any>(null);

function getAuthStore() {
  function Provider({ children }: IAuthProvider) {
    const [token, setToken] = useState(localStorage.getItem(APP_TOKEN_NAME));

    const setAuthToken = useCallback(
      (value: string) => {
        if (value) {
          localStorage.setItem(APP_TOKEN_NAME, JSON.stringify(value));
          setToken(value);
        }
      },
      [setToken]
    );

    const deleteAuthToken = useCallback(() => {
      localStorage.removeItem(APP_TOKEN_NAME);
      setToken(null);
    }, [setToken]);

    const contextValue = useMemo(() => token, [token]);

    return (
      <AuthContext.Provider value={{ token: contextValue, setAuthToken, deleteAuthToken }}>
        {children}
      </AuthContext.Provider>
    );
  }

  const useAuth = () => useContext<IAuthContext>(AuthContext);
  return { Provider, useAuth };
}

const AuthStore = getAuthStore();

export default AuthStore;

export const useLogout = () => {
  const client = useApolloClient();
  const { deleteAuthToken } = AuthStore.useAuth();

  return useCallback(async () => {
    deleteAuthToken();
    const apollo = await getApollo();
    await apollo.persistor.pause();
    await apollo.persistor.purge();

    return client.resetStore();
  }, [client, deleteAuthToken]);
};

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [loginWithEmailAndPassword, { data, error }] = useLoginWithEmailAndPasswordMutation();
  const { setAuthToken } = AuthStore.useAuth();

  const loginUser = async ({ email, password }: { email: string; password: string }) => {
    setLoading(true);

    if (email !== "" && password !== "") {
      try {
        const res = await loginWithEmailAndPassword({ variables: { email, password } });
        if (res.data && res.data.loginWithEmailAndPassword && res.data.loginWithEmailAndPassword.token) {
          setAuthToken(res.data.loginWithEmailAndPassword.token);
          setLoading(false);
        } else {
          // show Error alert, Toaster
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return { loginUser, error, loading, data };
};
