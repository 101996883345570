import React from "react";
import MainHeader from "components/organisms/MainHeader";

const MainLayout: React.FC = ({ children }) => {
  // const { data } = useGetIsSidemenuCollapsedQuery();

  return (
    <div>
      <MainHeader />
      {children}
    </div>
  );
};

export default MainLayout;

// Button toggle - wui-page--sidebar-collapsed
