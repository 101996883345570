import React from "react";
import Login from "pages/login/Login";
import { RouteComponentProps } from "@reach/router";
import AuthContext from "services/auth.service";

interface IPrivateRouteProps {
  as: React.FC;
}

const PrivateRoute: React.FC<IPrivateRouteProps & RouteComponentProps> = ({ as: Component, ...props }) => {
  const { token } = AuthContext.useAuth();
  return token ? <Component {...props} /> : <Login />;
};

export default PrivateRoute;
