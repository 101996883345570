import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
// import { withClientState } from "apollo-link-state";
import { ApolloLink } from "apollo-link";
import introspectionQueryResultData from "introspection-result.json";
import { APP_TOKEN_NAME } from "services/auth.service";
import { createUploadLink } from "apollo-upload-client";
import { CachePersistor } from "apollo-cache-persist";
import { PersistentStorage, PersistedData } from "apollo-cache-persist/types";
import typeDefs from "state/localTypes";
import { resolvers } from "state/resolvers";
import localStateDefaults from "state/default";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({
  fragmentMatcher
  //   cacheRedirects: {
  //     Query: {
  //       movie: (_, { id }, { getCacheKey }) =>
  //         getCacheKey({ __typename: 'Movie', id });
  //     }
  //   }
});

const requestLink = new ApolloLink((operation, forward) => {
  return forward && forward(operation);
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(APP_TOKEN_NAME);
  if (token !== "") {
    return {
      headers: {
        ...headers,
        "x-token": token
      }
    };
  }
  return headers;
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL
  // credentials: "include"
  // fetchOptions: {
  //   mode: "no-cors" // TODO: remove - or handle
  // }
});
function getErrorAfterware(cbHandleError?: (response: any) => void) {
  return onError((error) => {
    const { graphQLErrors, networkError, response } = error;
    cbHandleError && cbHandleError(response);
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        const { path } = error;

        console.log(`[GraphQL error]: Message: ${JSON.stringify(error)}, Path: ${path}`);
        //   sendToLoggingService(graphQLErrors);
        return error;
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      //   logoutUser();
    }
  });
}

async function configureClient(cbHandleError?: (response: any) => void) {
  const onErrorAfterware = getErrorAfterware(cbHandleError);
  client = new ApolloClient({
    link: ApolloLink.from([
      authLink,
      requestLink,
      onErrorAfterware,
      //   withClientState({
      //     defaults: {
      //       isConnected: true
      //     },
      //     resolvers: {
      //       Mutation: {
      //         updateNetworkStatus: (_, { isConnected }, { cache }) => {
      //           cache.writeData({ data: { isConnected }});
      //           return null;
      //         }
      //       }
      //     },
      //     cache
      //   }),
      // httpLink,
      uploadLink
    ]),

    //   defaultOptions,
    cache,
    typeDefs,
    resolvers
  });

  cache.writeData({
    data: localStateDefaults
  });
  //@ts-ignore
  client.onResetStore(() => cache.writeData({ data: localStateDefaults }));
  return client;
}

async function configurePersistor() {
  persistor = new CachePersistor({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>
  });
  persistor.getLogs(true);
  await persistor.restore();
  return persistor;
}

let client: ApolloClient<NormalizedCacheObject> | null = null;
let persistor: any = null;

async function getApollo(cbHandleError?: (response: any) => void) {
  if (!client || !persistor) {
    client = await configureClient(cbHandleError);
    persistor = await configurePersistor();
  }

  return { client, persistor };
}

export { getApollo };
